import React, { Component } from "react";
import FastArrowPng from "./img/fast-arrow/fast-arrow.png";
import FastArrowWebp from "./img/fast-arrow/fast-arrow.webp";
import OptimizedImage from "./OptimizedImage";
import config from "../App/config.json";

export default class CallToAction extends Component {
  render() {
    return (
      <div>
        <div className="jumbotron jumbotron-fluid bg-primary text-white text-center m-0 px-3 py-5">
          <div className="my-5">
            <h1 className="title-font ">Grid-ML XML Schema Viewer</h1>
            <OptimizedImage
              png={FastArrowPng}
              webp={FastArrowWebp}
              alt="high performance"
            />

            <h2 className="title-font">
              An easy-to-use online tool for viewing, searching, and comparing
              XML schemas.
            </h2>
            <button className="btn btn-danger mt-3 px-4 btn-lg">
              <a
                className="text-white font-weight-bold"
                href={config.AppUrl + "/GetStarted?priceId=2M&action=try"}
              >
                Start your 30-day free trial
              </a>
            </button>
          </div>
        </div>
      </div>
    );
  }
}
