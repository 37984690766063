import React, { Component } from "react";
import { Link } from "react-router-dom";
import { InlineIcon } from "@iconify/react";
import CopyrightIcon from "@iconify/icons-mdi/copyright";
import LinkedInIcon from "@iconify/icons-mdi/linkedin-box";
import ContactIcon from "@iconify/icons-mdi/email-outline";

export default class Footer extends Component {
  render() {
    // eslint-disable-next-line
    const { children, ...attributes } = this.props;
    const year = new Date().getFullYear();

    return (
      <footer className="app-footer px-5  bg-dark ">
        <div className="row w-100 pt-5 mt-3 ml-5">
          <div className="col-sm-12 col-md-6 col-lg-3 py-2  w-md-25">
            <p className="footer-title">Product</p>
            <p>
              <Link className="text-light" to="/xml-schema-viewer">
                Product Overview
              </Link>
            </p>
            <p>
              <Link className="text-light" to="/xsd-viewer">
                Schema Visualization
              </Link>
            </p>
            <p>
              <Link className="text-light" to="/xsd-visualizer">
                Schema Search
              </Link>
            </p>
            <p>
              <Link className="text-light" to="/xsd-diff-tool">
                Schema Comparison
              </Link>
            </p>
            <p>
              <Link className="text-light" to="/Features">
                Features
              </Link>
            </p>
            <p>
              <Link className="text-light" to="/Updates">
                Release Notes
              </Link>
            </p>
          </div>

          <div className="col-sm-12 col-md-6 col-lg-3 py-2 w-md-25">
            <p className="footer-title">Standards</p>
            <p>
              <Link className="text-light" to="/information-exchange-standards">
                Standards Overview
              </Link>
            </p>
            <p>
              <Link
                className="text-light"
                to="/niem-xml-viewer"
                href="/niem-xml-viewer"
              >
                NIEM
              </Link>
            </p>
            <p>
              <Link
                className="text-light"
                to="/mismo-model-viewer"
                href="/mismo-model-viewer"
              >
                MISMO
              </Link>
            </p>
            <p>
              <Link
                className="text-light"
                to="/mismo-model-viewer"
                href="/mismo-model-viewer"
              >
                FannieMae/FreddieMac UMDP
              </Link>
            </p>
          </div>

          <div className="col-sm-12 col-md-6 col-lg-3 py-2 w-md-25">
            <p className="footer-title">Resources</p>
            <p>
              <Link
                className="text-light"
                to="/mismo-ilad-overview"
                href="/mismo-ilad-overview"
              >
                Introduction to iLAD
              </Link>
            </p>
            <p>
              <Link
                className="text-light"
                to="/mismo-ilad-specification"
                href="/mismo-ilad-specification"
              >
                Exploring the iLAD Specification
              </Link>
            </p>
            <p>
              <Link
                className="text-light"
                to="/mismo-ilad-xml-schema"
                href="/mismo-ilad-xml-schema"
              >
                Using the iLAD XML Schema
              </Link>
            </p>
            <p>
              <Link
                className="text-light"
                to="/mismo-xml-extensions"
                href="/mismo-xml-extensions"
              >
                Understanding MISMO Extensions
              </Link>
            </p>
            <p>
              <Link className="text-light" to="/Training" href="/Training">
                Training Videos
              </Link>
            </p>
          </div>

          <div className="col-sm-12 col-md-6 col-lg-3 py-2 w-md-25">
            <p className="footer-title">Company</p>
            <p>
              <a className="text-light" href="mailto:info@gridml.com">
                <InlineIcon icon={ContactIcon} />
                <span className="pl-1">email: info@gridml.com</span>
              </a>
            </p>
            <p>
              <a
                className="text-light"
                href="https://www.linkedin.com/company/gridml"
                target="_blank"
                rel="noopener noreferrer"
              >
                <InlineIcon icon={LinkedInIcon} />
                <span className="pl-1">Follow us LinkedIn </span>
              </a>
            </p>
            <p>
              <Link className="text-light" to="/terms">
                Terms and Conditions
              </Link>
            </p>
            <p>
              <Link className="text-light" to="/privacy">
                Privacy Policy
              </Link>
            </p>
            <p>
              <Link className="text-light" to="/Security">
                Security
              </Link>
            </p>
          </div>
        </div>
        <div className="w-100 text-center mt-5 mb-3">
          <InlineIcon icon={CopyrightIcon} /> 2018-{year} GridML LLC. All rights
          reserved
        </div>
      </footer>
    );
  }
}
