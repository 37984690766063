import React, { Component } from "react";
import { Link } from "react-router-dom";
import LogoPng from "./img/grid-ml-logo/grid-ml-logo.png";
import LogoWebp from "./img/grid-ml-logo/grid-ml-logo.webp";
import OptimizedImage from "../Common/OptimizedImage";
import config from "../App/config.json";
import SearchIcon from "@iconify/icons-mdi/magnify";
import ExploreIcon from "@iconify/icons-mdi/compass-outline";
import CompareIcon from "@iconify/icons-mdi/file-compare";
import NotesIcon from "@iconify/icons-mdi/note-multiple-outline";
import OverviewIcon from "@iconify/icons-mdi/message-outline";
import FeaturesIcon from "@iconify/icons-mdi/format-list-bulleted-type";
import TrainingIcon from "@iconify/icons-mdi/school";
import BlogIcon from "@iconify/icons-mdi/newspaper";
import PlanIcon from "@iconify/icons-mdi/gift-outline";
import ValidationIcon from "@iconify/icons-mdi/check-box-outline";

import { Icon } from "@iconify/react";
import MismoLogoPng from "../Common/img/mismo-logo/mismo-logo.png";
import MismoLogoWebp from "../Common/img/mismo-logo/mismo-logo.webp";
import UmdpLogoPng from "../Common/img/umdp-logo/umdp-logo.png";
import UmdpLogoWebp from "../Common/img/umdp-logo/umdp-logo.webp";
import NiemLogoPng from "../Common/img/niem-logo/niem-logo.png";
import NiemLogoWebp from "../Common/img/niem-logo/niem-logo.webp";

export default class HeaderPublic extends Component {
  constructor(props) {
    super(props);
    this.state = { openMenu: null };
    this.handleHamburgerMenuClicked =
      this.handleHamburgerMenuClicked.bind(this);
    this.handleTopMenuClicked = this.handleTopMenuClicked.bind(this);
    this.handleTopMenuHovered = this.handleTopMenuHovered.bind(this);

    this.menus = [
      {
        caption: "Product",
        rows: 4,
        cols: 2,

        menus: [
          {
            caption: "Grid-ML Overview",
            url: "/xml-schema-viewer",
            text: "Learn all about how Grid-ML works, its benefits, and use-cases",
            row: 0,
            col: 0,
            icon: OverviewIcon,
          },
          {
            caption: "Schema Visualization",
            url: "/xsd-viewer",
            text: "Drill down into complex data structures and explore each item in context",
            row: 1,
            col: 0,
            icon: ExploreIcon,
          },
          {
            caption: "Schema Search",
            url: "/xsd-visualizer",
            text: "Quickly find items across multiples schemas and source files",
            row: 2,
            col: 0,
            icon: SearchIcon,
          },
          {
            caption: "Schema Comparison",
            url: "/xsd-diff-tool",
            text: "See all the differences between two schemas at a glance",
            row: 3,
            col: 0,
            icon: CompareIcon,
          },
          {
            caption: "XPath & JSONPath Validation",
            url: "/xpath-validation",
            text: "Check whether a given path is supported by a schema",
            row: 1,
            col: 1,
            icon: ValidationIcon,
          },
          {
            caption: "Features",
            url: "/Features",
            text: "Discover searching, navigation, types, XPath, JSONPath, and more",
            row: 2,
            col: 1,
            icon: FeaturesIcon,
          },
          {
            caption: "Release Notes",
            url: "/Updates",
            text: "Get the details on the latest Grid-ML updates",
            row: 3,
            col: 1,
            icon: NotesIcon,
          },
        ],
      },
      {
        caption: "Data Standards",
        rows: 4,
        cols: 1,
        menus: [
          {
            caption: "Data Standards Support",
            url: "/information-exchange-standards",
            text: "See how Grid-ML support for industry standards helps you do more, faster",
            row: 0,
            col: 0,
          },
          {
            caption: "MISMO®",
            url: "/mismo-model-viewer",
            text: "The Mortgage Industry Standard Maintenance Organization",
            row: 1,
            col: 0,
            png: MismoLogoPng,
            webp: MismoLogoWebp,
          },
          {
            caption: "UMDP",
            url: "/mismo-model-viewer",
            text: "The Uniform Mortgage Data Program® (UMDP®) is an effort undertaken jointly by Fannie Mae® and Freddie Mac® ",
            row: 2,
            col: 0,
            png: UmdpLogoPng,
            webp: UmdpLogoWebp,
          },
          {
            caption: "NIEM",
            url: "/niem-xml-viewer",
            text: "The National Information Exchange Model enables efficient information exchange across diverse public and private organizations",
            row: 3,
            col: 0,
            png: NiemLogoPng,
            webp: NiemLogoWebp,
          },
        ],
      },
      {
        caption: "Resources",
        rows: 2,
        cols: 1,
        menus: [
          {
            caption: "Articles",
            url: "/Articles",
            text: "Read expert articles created by our founders",
            row: 0,
            col: 0,
            icon: BlogIcon,
          },
          {
            caption: "Training",
            url: "/Training",
            text: "Watch video tutorials to help you get started with Grid-ML",
            row: 1,
            col: 0,
            icon: TrainingIcon,
          },
        ],
      },
      {
        caption: "Plans & Pricing",
        rows: 1,
        cols: 1,
        menus: [
          {
            caption: "Basic, Standard, Pro, or Enterprise",
            url: "/Pricing",
            text: "Choose the plan that is right for you. We have both free and paid plans, as well as trial options",
            row: 0,
            col: 0,
            icon: PlanIcon,
          },
        ],
      },
    ];
  }

  handleTopMenuHovered(item) {
    document.body.style.position = "";
    document.body.style.top = "";

    this.setState({ openMenu: item });
  }

  handleTopMenuClicked(item) {
    document.body.style.position = "";
    document.body.style.top = "";
    // if (this.state.openMenu === item) {
    //   item = null;
    // }
    this.setState({ openMenu: item });
  }

  handleHamburgerMenuClicked(item) {
    if (this.state.openMenu === item) {
      item = null;
    }
    this.setState({ openMenu: item });
    window.scrollTo(0, 0);
  }

  getHamburgerMenu() {
    if (this.state.openMenu !== -1) return <div />;
    return (
      <table className="justify-content-left w-100 bg-white border border-secondary app-header-spacer  ">
        <tbody>
          {this.menus.map((menu) => (
            <tr key={"menu_" + menu.caption} className=" border border-muted">
              <td>
                {menu.url != null ? (
                  <Link
                    className=" py-3 px-3 top-menu top-menu-text  d-block"
                    to={menu.url}
                    href={menu.url}
                    onClick={() => this.handleTopMenuClicked(null)}
                  >
                    {menu.caption}
                  </Link>
                ) : (
                  <div className=" py-3 px-3  top-menu-text  d-block">
                    {menu.caption}
                    <div className="pl-2">
                      {menu.menus.map((sub, i) => (
                        <Link
                          key={sub.caption + i}
                          className=" py-3 px-3 top-menu top-menu-text  d-block"
                          to={sub.url}
                          href={sub.url}
                          onClick={() => this.handleTopMenuClicked(null)}
                        >
                          {sub.caption}
                        </Link>
                      ))}
                    </div>
                  </div>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  }

  getMenuItem(items, row, col) {
    const style = {
      fontSize: "2.5rem",
    };
    for (let i = 0; i < items.length; i++) {
      const item = items[i];
      if (item.row === row && item.col === col) {
        return (
          <td className="px-2 py-1  ">
            <Link
              className=" hover-no-underline "
              to={item.url}
              href={item.url}
            >
              <div className="sub-menu px-2 pt-3 pb-1">
                <table>
                  <tbody>
                    <tr>
                      <td className="px-3  align-top">
                        {item.icon && (
                          <Icon
                            style={style}
                            icon={item.icon}
                            className=" text-primary "
                          />
                        )}
                        {item.png && (
                          <div className="pt-2">
                            <OptimizedImage
                              maxWidth="100"
                              png={item.png}
                              webp={item.webp}
                            />
                          </div>
                        )}
                      </td>
                      <td>
                        <p className="top-menu-text m-0 text-dark">
                          {item.caption}
                        </p>
                        <p className="small-text mr-3 font-weight-normal menu-width text-muted">
                          {item.text}
                        </p>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </Link>
          </td>
        );
      }
    }

    return null;
  }

  getMenuRow(menu, row) {
    const itemA = this.getMenuItem(menu.menus, row, 0);
    const itemB = this.getMenuItem(menu.menus, row, 1);

    if (itemA == null && itemB == null) return null;

    return (
      <tr key={"menu_" + row}>
        {itemA || <td />}
        {itemB || <td />}
      </tr>
    );
  }

  getOpenMenu() {
    if (this.state.openMenu !== null && this.state.openMenu !== -1) {
      const menu = this.menus[this.state.openMenu];
      const items = menu.menus;
      if (items == null) return "";

      let ele = document.getElementById("top_menu_" + this.state.openMenu);
      const eleRect = ele.getBoundingClientRect();

      // Calculate the left position
      const left = eleRect.left;
      const style = {
        marginLeft: left + "px",
      };

      return (
        <div
          className=" modal-overlay  "
          onClick={() => this.handleTopMenuClicked(null)}
        >
          <div className="app-header-spacer py-1 " />
          <div
            style={style}
            className=" shadow more-rounded border border-dark bg-white d-inline-flex "
          >
            <table className="m-3 ">
              <tbody>
                {items.map((item, i) => {
                  return this.getMenuRow(menu, i);
                })}
              </tbody>
            </table>
          </div>
        </div>
      );
    } else {
      return <div />;
    }
  }

  getLogo() {
    return (
      <div className="flex-column">
        <Link
          className="ml-2 px-2 title-font logo-link d-flex flex-row"
          to="/xml-schema-viewer"
          href="/xml-schema-viewer"
        >
          <OptimizedImage
            png={LogoPng}
            webp={LogoWebp}
            alt="Grid-ML XML Schema Viewer"
            className="d-block"
            width="32"
          />
          <h4 className="px-2 pt-1 mb-0 logo-link ">Grid-ML</h4>
        </Link>
      </div>
    );
  }

  getHorizontalMenu() {
    return (
      <div className="mt-2 pr-2 flex-column ">
        {this.menus.map((menu, i) => (
          <span id={"top_menu_" + i} key={"menu_" + menu.caption}>
            {menu.url != null ? (
              <Link
                className={
                  " py-0 px-3 mx-2  top-menu-text  " +
                  (i === this.state.openMenu
                    ? "top-menu-selected"
                    : " top-menu")
                }
                to={menu.url}
                href={menu.url}
                onClick={() => this.handleTopMenuClicked(i)}
              >
                {menu.caption}
              </Link>
            ) : (
              <span
                className={
                  " py-0 px-3 mx-2 top-menu top-menu-text  " +
                  (i === this.state.openMenu
                    ? "top-menu-selected"
                    : " top-menu")
                }
                onClick={() => this.handleTopMenuClicked(i)}
                onMouseOver={() => this.handleTopMenuHovered(i)}
              >
                {menu.caption}
              </span>
            )}
          </span>
        ))}
      </div>
    );
  }

  getButton() {
    return (
      <div className=" flex-column ">
        <button
          className="  mr-3 px-4 btn btn-danger text-white"
          color="danger"
        >
          <a href={config.AppUrl} className="text-white font-weight-bold">
            User Sign in
          </a>
        </button>
      </div>
    );
  }

  render() {
    return (
      <div>
        <header className="app-header shadow navbar bg-white">
          <div className="w-100  d-none d-lg-flex flex-row justify-content-between">
            {this.getLogo()}
            {this.getHorizontalMenu()}
            {this.getButton()}
          </div>
          <div className=" w-100 d-flex d-lg-none flex-row justify-content-between">
            <div
              className={"ml-2  hamburger-menu text-muted px-2 "}
              onClick={() => this.handleHamburgerMenuClicked(-1)}
            >
              {this.state.openMenu === -1 ? "✕" : "☰"}
            </div>
            {this.getLogo()}
            {this.getButton()}
          </div>
        </header>
        <div className="app-header-spacer" />
        {this.getOpenMenu()}
        {this.getHamburgerMenu()}
      </div>
    );
  }
}
