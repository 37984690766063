import React, { Component } from "react";

export default class OptimizedImage extends Component {
  render() {
    let style = {};

    if (this.props.maxWidth) style.maxWidth = this.props.maxWidth + "px";

    if (this.props.width) {
      if (this.props.width.includes("%")) {
        style.width = this.props.width;
      } else {
        style.width = this.props.width + "px";
      }
    }

    let className =
      " img-fluid " +
      (this.props.shadow === true ? " border border-muted rounded shadow" : "");
    if (this.props.className) className = this.props.className;

    return (
      <picture>
        <source type="image/webp" srcSet={this.props.webp} />
        <source type="image/png" srcSet={this.props.png} />
        <img
          style={style}
          src={this.props.png}
          alt={this.props.alt}
          className={className}
        />
      </picture>
    );
  }
}
